import { useState, useEffect } from 'react';
// import offlineGuestData from '../../api/guest-data.json';

function useGuestData(code) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const handleFetch = async () => {
    setLoading(true);

    try {
      const response = await fetch(`https://api.vinhnhatwedding.com/api/v1/guest/${code}`);
      const fetchedData = await response.json();
      if (fetchedData.err === true) {
        alert(`Không tìm thấy code ${code}`);
        window.location.href = '/';
        return;
      }
      setData(fetchedData || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return { data, loading, refetch: handleFetch };
}

export default useGuestData;
